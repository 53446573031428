import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import banner from "../../images/website-images/Mask.png"

const Head = () => {
    return (
        <>
            <Box display={{xs: 'none', sm: 'block', md: 'block'}}>
                <Box paddingTop='4rem'>
                    <Typography textAlign='center' fontSize='45px' color="#3A3F3C" mb='2rem'
                        sx={{ fontFamily: "'GeneralSansSemibold', sans-serif", letterSpacing: '-0.9px' }}
                    >About Us</Typography>
                    <Stack direction={{ xs: 'column-reverse', sm: 'row', md: 'row' }} pt='2rem' justifyContent='space-between' width='78%' margin='auto'>
                        <Box>
                            <Typography fontSize='32px' fontWeight='600' color="#3A3F3C" sx={{ fontFamily: "'GeneralSansSemibold', sans-serif", letterSpacing: '-0.7px' }}>Transforming Pharmacy Supply <br /> Chains With Precision and Care</Typography>
                            <Typography fontSize='15px' color="#3C4959" mt='14px' fontFamily="'GeneralSansRegular', sans-serif">At Anahad, our mission is to redefine the pharmacy supply chain with innovative, <br />
                                tech-driven solutions that enhance efficiency, reliability, and accessibility for local <br />
                                pharmacies. We understand the critical role pharmacies play in ensuring the health <br /> and
                                well-being of their communities, and we are committed to empowering them <br /> with the tools
                                and resources they need to thrive. From offering a wide range of <br /> trusted pharmaceutical
                                brands to streamlining the delivery process, we aim to <br /> eliminate inefficiencies and
                                bridge gaps in the supply chain, ensuring pharmacies <br /> can serve their customers without disruption.
                            </Typography>
                            <Typography fontSize='15px' color="#3C4959" mt='14px' fontFamily="'GeneralSansRegular', sans-serif">
                                Our approach is built on a foundation of transparency, innovation, and customer- <br />centricity.
                                By leveraging cutting-edge technology and industry insights, we aspire <br /> to create a robust
                                ecosystem where every pharmacy, regardless of its scale, can <br /> access quality medicines and
                                track their orders seamlessly. Our mission goes <br /> beyond supply chain optimization—we aim to
                                strengthen the backbone of <br /> healthcare and contribute to a future where every community has
                                reliable access <br /> to essential medicines. At Anahad, we believe in partnering with pharmacies
                                to <br /> build a healthier, more connected world.
                            </Typography>
                        </Box>
                        <Box component={'img'} src={banner} alt="banner" />
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default Head