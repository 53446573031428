import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import dummy_map from "../../images/website-images/dummy_map.png";
import location_icon from "../../images/website-images/location_pin.png";
import clock_icon from "../../images/website-images/clock.png";
import cellPhone_icon from "../../images/website-images/cell_phone.png";
import AppointmentForm from "./AppointmentForm";

const GetInTouch = () => {
    return (
        <Box sx={{ padding: "20px", backgroundColor: "#f4f4f4 ", borderRadius: "8px",  }}>
            {/* Header Section */}
            <Box sx={{ marginBottom: "20px", textAlign: "center" }}>
                <Typography variant="h5" sx={{ fontWeight: "bold", color: "#333" }}>
                    Get in Touch
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "#666", marginTop: "8px" }}>
                    Become Your Trusted Partner in Medicine Supply
                </Typography>
            </Box>

            {/* Map Section */}
            <Stack direction='row' width={'78%'} margin='auto' justifyContent='space-between'>
            <Box>
            <Box sx={{ position: "relative", marginBottom: "20px" }}>
                <Box
                    component="img"
                    src={dummy_map}
                    alt="map"
                    sx={{
                        width: "480px",
                        height: "320px",
                        borderRadius: "8px",
                    }}
                />
                {/* Address Section */}
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "10px",
                        left: "10px",
                        backgroundColor: "#fff",
                        // padding: "12px",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Stack direction="row" spacing={2} alignItems="flex-start" sx={{background: '#fff', padding: '1rem', borderRadius: '15px', width: '84%'}}>
                        <Box>
                        <Box component="img" src={location_icon} alt="location-icon" sx={{ width: "20px", height: "20px", padding: '11px', borderRadius: '30px', backgroundColor: '#71CA9B' }} />
                        </Box>
                        <Box>
                            <Typography sx={{ fontWeight: "bold", color: "#333" }}>Anahad Office Address</Typography>
                            <Typography variant="body2" sx={{ color: "#666", marginTop: "4px" }}>
                                101, LD building, 1st floor, Mehra Industrial Estate, Lal <br />
                                Bahadur Shastri Marg, Vikhroli West, Mumbai, <br />
                                Maharashtra 400079
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            </Box>

            {/* Details Section */}
            <Stack spacing={2}>
                {/* Office Timings */}
                <Stack direction="row" spacing={2} alignItems="center" sx={{background: '#fff', padding: '1rem', borderRadius: '15px', width: '84%'}}>
                    <Box component="img" src={clock_icon} alt="clock-icon" sx={{ width: "20px", height: "20px", padding: '11px', borderRadius: '30px', backgroundColor: '#71CA9B'  }} />
                    <Box>
                        <Typography sx={{ fontWeight: "bold", color: "#333" }}>Office Timings</Typography>
                        <Typography variant="body2" sx={{ color: "#666", marginTop: "4px" }}>
                            Monday - Saturday (9:00am to 5pm) <br />
                            Sunday (Closed)
                        </Typography>
                    </Box>
                </Stack>

                {/* Email Address */}
                <Stack direction="row" spacing={2} alignItems="center" sx={{background: '#fff', padding: '1rem', borderRadius: '15px', width: '84%'}}>
                    <Box component="img" src={location_icon} alt="email-icon" sx={{width: "20px", height: "20px", padding: '11px', borderRadius: '30px', backgroundColor: '#71CA9B' }} />
                    <Box>
                        <Typography sx={{ fontWeight: "bold", color: "#333" }}>Email Address</Typography>
                        <Typography variant="body2" sx={{ color: "#666", marginTop: "4px" }}>
                            shikhar@Anahad.in
                        </Typography>
                    </Box>
                </Stack>

                {/* Phone Number */}
                <Stack direction="row" spacing={2} alignItems="center" sx={{background: '#fff', padding: '1rem', borderRadius: '15px', width: '84%'}}>
                    <Box component="img" src={cellPhone_icon} alt="phone-icon" sx={{ width: "20px", height: "20px", padding: '11px', borderRadius: '30px', backgroundColor: '#71CA9B' }} />
                    <Box>
                        <Typography sx={{ fontWeight: "bold", color: "#333" }}>Phone Number</Typography>
                        <Typography variant="body2" sx={{ color: "#666", marginTop: "4px" }}>
                            +91 88222-88222
                        </Typography>
                    </Box>
                </Stack>
            </Stack>
            </Box>

            <Box width='48%'>
                <AppointmentForm/>
            </Box>
            </Stack>
        </Box>
    );
};

export default GetInTouch;
