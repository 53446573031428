import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Progressbar from "../../Atoms/LinearProgressBar";
import cartimg from "../../images/cart.png"
import docimg from "../../images/money-icon.png"
import rocketimg from "../../images/rocket.png"
import axios from "axios";
import Config from "../../utils/Config";
import { AuthContext } from "../../Context/AuthContext";

const OrderStatusChart = ({ fromDate, toDate }) => {

    const [orderstatus, setOrderStatus] = useState();
    const {apiToken} = useContext(AuthContext)

    // const data = [
    //     { name: 'Bill Generation', value: 200 },
    //     { name: 'Preparing Dispatch', value: 500 },
    //     { name: 'Goods Dispatch', value: 300 },
    //     { name: 'Out for Delivery', value: 200 },
    //     { name: 'Order Delivered', value: 180 },
    // ];

    const GetOrderStatusChart = async () => {
        try {
            const response = await axios.post(Config?.Get_Order_Status_Graph, {
                startDate: fromDate,
                endDate: toDate
            },{
                headers: {
                    Authorization: `Bearer ${apiToken}`
                }
            })
            if (response?.status === 200) {
                const data = response?.data?.apiData;
                const formattedData = [
                    { name: "Bill Generation", value: data.billGenerationCount },
                    { name: "Preparing Dispatch", value: data.preparingDispatchCount },
                    { name: "Goods Dispatch", value: data.goodsDispatchCount },
                    { name: "Out for Delivery", value: data.outForDeliveryCount },
                    { name: "Order Delivered", value: data.orderDeliveredCount },
                  ];
                  setOrderStatus(formattedData)
                console.log(response?.data?.apiData, 'orderStatusData')
            }
        } catch (error) {
            console.log(error, 'Error in fetching Api response.')
        }
    } 

    useEffect(()=>{
        GetOrderStatusChart()
    },[fromDate, toDate])

    const CustomTick = ({ x, y, payload }) => {
        // Define how you want to split long labels into two lines
        const label = payload.value;
        let lines;

        if (label === "Out for Delivery") {
            lines = ["Out for", "Delivery"]; // Manually split this specific label
        } else {
            lines = label.split(" "); // Default splitting for other labels
        }

        return (
            <text x={x} y={y + 10} fill="#E6E6E6" fontSize={12} textAnchor="middle">
                {lines.map((line, index) => (
                    <tspan x={x} dy={index === 0 ? 0 : 14} key={index}>
                        {line}
                    </tspan>
                ))}
            </text>
        );
    };
    return (
        <>
            <div
                style={{
                    width: "470px",
                    // width: "504px",
                    height: '222px',
                    background: "linear-gradient(105deg, rgb(33 102 62), #1B3B33)", // Dark gradient background
                    borderRadius: "20px", // Rounded corners on the container
                    padding: "10px",
                }}
            >
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={orderstatus}
                        margin={{ top: 20, right: 20, left: 0, bottom: 0 }}
                    >
                        {/* <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="rgba(255, 255, 255, 0.2)" /> */}
                        <XAxis
                            dataKey="name"
                            tick={<CustomTick />}
                            interval={0}
                            height={40}
                            tickLine={false}
                        />
                        <YAxis tick={{ fill: "#E6E6E6", fontSize: 12 }} interval={0} domain={[0, 500]} ticks={[0, 100, 200, 300, 400, 500]} />
                        <Bar dataKey="value" fill="#ffffff" radius={[10, 10, 0, 0]} barSize={60} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Box>
                <Typography variant="h4" sx={{ color: '#2D3748', fontSize: '18px', fontWeight: 'bold', mt: '24px', mb: 1 }}>Order Status Breakdown</Typography>
                <Stack direction='row' gap='6px' mb='2rem'>
                    <Typography variant="body1" sx={{ color: '#66bb6a', fontSize: '14px' }}>(+23)</Typography>
                    <Typography variant="body1" sx={{ color: 'grey.500', fontSize: '14px' }}> then last week</Typography>
                </Stack>

                <Stack direction='row' width='530px' justifyContent='space-between'>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='10px' marginBottom='8px'>
                            <Box component={'img'} src={docimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Pending</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>32,984</Typography>
                        <Progressbar value={60} />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='10px' marginBottom='8px'>

                            <Box component={'img'} src={rocketimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />

                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Completed</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>20,264</Typography>
                        <Progressbar value={40} />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='10px' marginBottom='8px'>
                            <Box component={'img'} src={cartimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Cancelled</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>10,243</Typography>
                        <Progressbar value={25} />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='10px' marginBottom='8px'>
                            <Box component={'img'} src={docimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Pending</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>32,984</Typography>
                        <Progressbar value={60} />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='10px' marginBottom='8px'>
                            <Box component={'img'} src={docimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Pending</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>32,984</Typography>
                        <Progressbar value={60} />
                    </Box>
                </Stack>
            </Box>
        </>
    )
}

export default OrderStatusChart