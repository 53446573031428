import { Box, Button, Input, Stack, Typography } from "@mui/material";
import React from "react";
import pharmacySupply from "../../images/website-images/supply_pharmacy.png";
import call_icon from "../../images/website-images/cell-phone.png"

const PharmacySupply = () => {
    return (
        <>
            <Box>
                <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent={{ md: 'space-around' }} padding={{ xs: '4rem 0rem', md: '5rem 0px' }} width={"90%"} margin='auto'>
                    <Box>
                        <Box className='pharmcy_supply_main' sx={{ width: { xs: '96%', sm: '89%', md: '100%' }, margin: { xs: 'auto', sm: 'auto', md: 'unset' } }}>
                            <Typography className="heading" color="#3A3F3C"
                                sx={{
                                    fontFamily: "'GeneralSansSemibold', sans-serif",
                                    fontSize: { xs: '32px', sm: '32px', md: '40px' },
                                    lineHeight: { xs: '44px', sm: '44px', md: '46px' },
                                    letterSpacing: '-1px',
                                    marginBottom: { xs: '12px', sm: '1rem', md: '1rem' }
                                }}>Join India's Leading <br /> Pharmacy Supply <br /> Network.</Typography>
                            <Typography className="subhead" color="#3C4959"
                                sx={{ fontFamily: "'GeneralSansRegular', sans-serif", fontSize: { xs: '16px', sm: '18px', md: '21px' }, letterSpacing: '-0.5px' }}>Let's work together to keep your shelves <br /> stocked, your customers satisfied, and your <br /> business thriving</Typography>
                        </Box>
                        <Stack className='input_section' direction='row' margin={{ xs: '20px 14px 40px 6px', md: '32px 0px' }} style={{ outline: 'none', backgroundColor: 'white' }} position='relative'>
                            <Box component={'img'} src={call_icon} alt="cell-phone" position='absolute' top='11px' left='11px' width='25px' />
                            <input
                                placeholder="Enter your Phone Number"
                                style={{
                                    width: '245px',
                                    paddingLeft: '48px',
                                    border: '1px solid #CECECE',
                                    height: '46px',
                                    outline: 'none',
                                    borderRadius: '8px 0px 0px 8px'
                                }}
                                className="pharmacy_input"
                                onFocus={(e) => e.target.style.outline = 'none'}
                                onBlur={(e) => e.target.style.outline = 'none'}
                            />
                            <Button 
                            style={{ backgroundColor: '#60BC8C', 
                            color: 'white', padding: '0px 20px', 
                            height: '48px', borderRadius: '0px 8px 8px 0px', 
                            fontSize: '14px', textTransform: 'capitalize' }} 
                            sx={{ fontFamily: "'GeneralSansRegular', sans-serif" }}>Submit</Button>
                        </Stack>
                    </Box>
                    <Box className='pharmacy_supply_img' width={{ xs: '91%', sm: '86%', md: "389px" }} sx={{ margin: { xs: 'auto', sm: 'auto', md: 'unset' } }} maxWidth="389px">
                        <Box component="img" src={pharmacySupply} alt="pharmacy-supply" width="100%" height="auto" />
                    </Box>
                </Stack>
            </Box>
        </>
    )
}

export default PharmacySupply