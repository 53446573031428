import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#d3d3d3', // Track color
        },
        bar: {
          backgroundColor: '#71CA9B', // Progress bar color
        },
      },
    },
  },
});

function Progressbar({value}) {
  return (
    <ThemeProvider theme={theme}>
      <LinearProgress variant="determinate" value={value} sx={{ width: '80%',
        maxWidth: '98px', borderRadius: '8px'}}/>
    </ThemeProvider>
  );
}

export default Progressbar;
