import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import banner from "../../images/website-images/Mask_2.png"

const ResponsiveHead = () => {
    return (
        <>
            <Box display={{xs: 'block', sm: 'none', md: 'none'}}>
                <Box paddingTop='4rem'>
                    <Typography textAlign='center' color="#3A3F3C" 
                        sx={{ fontFamily: "'GeneralSansSemibold', sans-serif", letterSpacing: '-0.9px', mb: {md: '2rem'}, fontSize:{xs: '38px',sm: '45px', md:'45px'} }}
                    >About Us</Typography>
                    <Stack direction={{ xs: 'column-reverse', sm: 'row', md: 'row' }} pt={{xs: '24px', sm: '2rem',md:'2rem'}} justifyContent='space-between' width='89%' margin='auto'>
                        <Box paddingLeft='10px' mt='2rem'>
                            <Typography fontSize='32px' fontWeight='600' color="#3A3F3C" sx={{ fontFamily: "'GeneralSansSemibold', sans-serif", letterSpacing: '-0.7px', lineHeight: '40px' }}>Transforming <br/> Pharmacy Supply <br /> Chains With <br/> Precision and <br/> Care</Typography>
                            <Typography fontSize='14px' color="#3C4959" mt='14px' fontFamily="'GeneralSansRegular', sans-serif">At Anahad, our mission is to redefine the <br/> pharmacy supply chain with innovative, <br/> tech-driven solutions that enhance <br/> efficiency, reliability, and accessibility for <br/> local pharmacies. We understand the <br/> critical role pharmacies play in ensuring the <br/> health and well-being of their communities, <br/> and we are committed to empowering <br/> them with the tools and resources they <br/> need to thrive. From offering a wide range <br/> of trusted pharmaceutical brands to <br/> streamlining the delivery process, we aim <br/> to eliminate inefficiencies and bridge gaps <br/> in the supply chain, ensuring pharmacies <br/> can serve their customers without <br/> disruption.
                            </Typography>
                            <Typography fontSize='14px' color="#3C4959" mt='14px' fontFamily="'GeneralSansRegular', sans-serif">
                            Our approach is built on a foundation of <br/> transparency, innovation, and customer- <br/> centricity. By leveraging cutting-edge <br/> technology and industry insights, we aspire <br/> to create a robust ecosystem where every <br/> pharmacy, regardless of its scale, can <br/> access quality medicines and track their <br/> orders seamlessly. Our mission goes <br/> beyond supply chain optimization—we aim <br/> to strengthen the backbone of healthcare <br/> and contribute to a future where every <br/> community has reliable access to essential <br/> medicines. At Anahad, we believe in <br/> partnering with pharmacies to build a <br/> healthier, more connected world.
                            </Typography>
                        </Box>
                        <Box component={'img'} src={banner} alt="banner" />
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default ResponsiveHead