import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashboard';
import Orders from './pages/Orders';
import Pharmacies from './pages/Pharmacies';
import OrderDetails from './pages/OrderDetails';
import Login from './pages/Login';
import './App.css';
import '../src/Style/Responsive.css'
import Home from './pages/Home';
import { AuthProvider } from './Context/AuthContext';
import AboutUs from './AnahadWebsite/AboutUs/AboutUsPage';
import Contact from './AnahadWebsite/Contact/ContactPage';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';

const theme = createTheme();

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <AuthProvider>
        <Routes>
          {/* Unprotected Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          {/* <Route path="/contact" element={<Contact />} /> */}

          {/* Protected Routes */}
          <Route path="/orders" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
          <Route path="/orderdetails/:orderId" element={<ProtectedRoute><OrderDetails /></ProtectedRoute>} />
          <Route path="/pharmacies" element={<ProtectedRoute><Pharmacies /></ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        </Routes>
      </AuthProvider>
    </Router>
  </ThemeProvider>
);

export default App;
