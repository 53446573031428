import { Box } from "@mui/material";
import React, { useState } from "react";
import Navbar from "../HomeScreen/Navbar";
import Head from "./Head";
import WhatWeDo from "./WhatWeDo";
import FeatureCards from "./FeatureCards";
import VideoContainer from "./VideoComponent";
import Footer from "../HomeScreen/Footer";
import ResponsiveHead from "./ResponsiveHead";

const AboutUs = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    return (
        <>
            <Box sx={{ backgroundColor: '#fff' }} display={{ xs: 'none', sm: 'block', md: 'block' }}>
                <Box
                    sx={{ background: 'radial-gradient(circle at top right, #b5edd0, #d4efe170 23%, rgba(255, 255, 255, 0.2) 28%),radial-gradient(circle at bottom left, #ebe1ca52, #efebe33d 14%, rgba(255, 255, 255, 0.5) 28%)' }}>
                    <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                    <Head />
                </Box>
                <WhatWeDo />
                <FeatureCards />
                <VideoContainer />
                <Footer />
            </Box>

            <Box sx={{ background: '#ebe1ca00', display: { xs: 'block', sm: 'none', md: 'none' } }}>
                <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                <Box sx={{
                    marginTop: isDrawerOpen ? "253px" : "0",
                    transition: "margin-top 0.3s ease",
                }}>
                    <Box sx={{ background: 'radial-gradient(circle at top left, #ade9c952, #f6fffa30 7%, rgba(255, 255, 255, 0.5) 45%)' }}>
                        <ResponsiveHead/>
                    </Box>
                    <WhatWeDo />
                    <FeatureCards />
                    <VideoContainer />
                    <Footer />
                </Box>
            </Box>
        </>
    )
}

export default AboutUs