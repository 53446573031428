import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { apiToken } = useContext(AuthContext);

  return apiToken ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
