import React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 23,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 19,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(18px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#71CA9B",
        opacity: 1,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 19,
    height: 19,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
    transition: theme.transitions.create(["width", "transform"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

