import React, { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";
import Config from "../../utils/Config";
import { CustomToast } from "../../Atoms/CustomToast";

export const LogoutFunction = () => {
  const { apiToken } = useContext(AuthContext);

  // Get addToast and ToastContainer from CustomToast
  const { addToast } = CustomToast();

  const Logout_API = async () => {
    try {
      const response = await axios.post(
        Config?.Logout,
        {},
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );

      if (response?.status === 200) {
        console.log(response?.data?.message, "logmessage");

        // Show success toast
        addToast({
          message: response?.data?.message || "Logout Successfully",
          type: "success",
        });
      }
    } catch (error) {
      console.log(error, "Error in fetching API....");

      // Show error toast
      addToast({
        message: "Server error",
        type: "error",
      });
    }
  };

  const ClearLocalStorage = () => {
    localStorage.clear();
    window.location.replace("/login");
  };

  return { Logout_API, ClearLocalStorage};
};
