import { Box } from "@mui/material";
import React, { useState } from "react";
import Navbar from "./Navbar";
import Banner from "./Banner";
import Statistics from "./Statistics";
import FeatureCards from "./Cards";
import PharmacySupply from "./PharmacySupply";
import Footer from "./Footer";
import AffiliatedBrandsWeb from "./AffiliatedBrandsWeb";
import AffiliatedBrandsResponsive from "./AffiliatedBrandResponsive";

const HomePage = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    return (
        <>
            <Box sx={{ backgroundColor: '#fff', display: { xs: 'none', sm: 'block', md: 'block' } }} >
                <Box sx={{ background: 'radial-gradient(circle at top right, #b5edd0, #d4efe170 23%, rgba(255, 255, 255, 0.2) 28%),radial-gradient(circle at bottom left, #ebe1cac9, #efebe33d 26%, rgba(255, 255, 255, 0.5) 28%)', height: '848px' }}>
                    <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                    <Banner />
                </Box>
                <Statistics />
                <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                    <AffiliatedBrandsWeb />
                </Box>
                <Box display={{ xs: 'block', sm: 'block', md: 'none' }}>
                    <AffiliatedBrandsResponsive />
                </Box>
                <FeatureCards />
                <PharmacySupply />
                <Footer />
            </Box>
            <Box sx={{ background: '#ebe1ca00', display: { xs: 'block', sm: 'none', md: 'none' } }} >
                <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                <Box sx={{
                    marginTop: isDrawerOpen ? "253px" : "0",
                    transition: "margin-top 0.3s ease",
                }}>
                    <Box sx={{ background: 'radial-gradient(circle at bottom left, #ebe1cad9, #efebe38f 16%, rgba(255, 255, 255, 0.5) 28%), radial-gradient(circle at top left, #97ecbf4f, #dfeae430 16%, rgb(255 255 255 / 94%) 18%)' }}>
                        <Banner />
                    </Box>
                    <Statistics />
                    <AffiliatedBrandsResponsive />
                    <FeatureCards />
                    <PharmacySupply />
                    <Footer />
                </Box>
            </Box>
        </>
    )
}

export default HomePage