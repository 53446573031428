import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Anahad_logo from "../images/logo_anahad.svg";
import anahad_animated_img from "../images/anahad-home-page-image.svg";
import HomePage from "../AnahadWebsite/HomeScreen/HomePage";

const Home = () => {
    return (
        <>
            <HomePage />

            {/* <Box mx={'1rem'} width='76%' margin='auto'>
                <Box pt='16px'>
                    <Box component={'img'} src={Anahad_logo} alt="anahad_logo" />

                </Box>
                <Stack direction={'row'}>
                    <Box className="hero-copy">
                        <Typography className="hero-title">Healing for everyone, at all times.</Typography>
                        <p style={{ fontWeight: 200, fontSize: '70px', lineHeight: '75px', fontFamily: 'Mukta, sans-serif', color: '#182538' }}>Pharmacies Trusted Partner In Medicine Supply</p>
                        <Typography className="hero-paragraph"><span className="hero-paragraph-green">Now,</span> <span style={{ fontSize: 'larger', letterSpacing: '1px' }}>order your medicines and wellness products on Anahad App for </span> <span className="hero-paragraph-green"> free delivery in 30 minutes,</span> <span style={{ fontSize: 'larger', letterSpacing: '1px' }}> at the most affordable prices! </span></Typography>
                        <Typography className="hero-paragraph">
                            <span className="hero-paragraph-green">Anahad </span>
                            <span style={{ fontSize: 'larger', letterSpacing: '1px' }}>
                                delivers reliable and efficient medicine supply solutions to pharmacies, ensuring timely and </span>
                            <span className="hero-paragraph-green"> hassle-free delivery for smoother operations.</span></Typography>
                    </Box>
                    <Box className="hero-media is-loaded">
                        <Box className="hero-media-image" component={'img'} src={anahad_animated_img} alt="animated_image" />
                    </Box>
                </Stack>
            </Box> */}
        </>
    )
}

export default Home