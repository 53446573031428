import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const CustomToast = () => {
  const addToast = (newRes) => {
    toast[newRes.type || "info"](newRes.message || "Notification", {
      position: newRes.position || "bottom-center",
      autoClose: newRes.duration || 500,
      hideProgressBar: true, // Hide the progress bar
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      // Custom styling
      style: {
        backgroundColor: newRes.backgroundColor || "#D5F4E1", // Default to green
        color: newRes.textColor || "#000", // Default to white text
        borderRadius: "8px", // Rounded corners
        fontSize: "16px", // Font size
        padding: "10px", // Padding inside the toast
        transition: "all 0.3s ease-in-out",
      },
      closeButton: false,
    });
  };

  return { addToast, ToastContainer: <ToastContainer /> };
};
