import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, IconButton, Stack, Button, TextField, InputAdornment } from '@mui/material';
import language_icon from '../images/language.png'
import page_icon from '../images/page.png'
import document from '../images/todaymoney.png';
import { AuthContext } from '../Context/AuthContext';
import axios from 'axios';
import Config from '../utils/Config';

const DashboardOrderStats = ({ fromDate, toDate }) => {

  const [dashStats, setDashStats] = useState({}); // Initialize as an object
  const { apiToken } = useContext(AuthContext);
  // console.log(apiToken, 'APITOKEN')

  const Get_Order_Details = async () => {
    try {
      const response = await axios.post(
        Config.Dashboard_details,
        {
          startDate: fromDate,
          endDate: toDate,
        },
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data.apiData;
        setDashStats(data || {}); // Ensure `dashStats` is always an object
      } else {
        console.log('Something Went wrong');
      }
    } catch (error) {
      console.error('Error in Fetching API response..', error);
    }
  };

  useEffect(() => {
    Get_Order_Details()
  }, [fromDate, toDate])

  const data = [
    { title: "Total Orders", value: "$53,000", percentage: "+55%", percentageColor: "#48BB78", icon: document },
    { title: "Pending Orders", value: "2,300", percentage: "+5%", percentageColor: "#48BB78", icon: language_icon },
    { title: "Completed Orders", value: "+3,052", percentage: "-14%", percentageColor: "#E53E3E", icon: page_icon },
    { title: "Order Value", value: "$53,000", percentage: "+55%", percentageColor: "#48BB78", icon: document },
    { title: "Gross Profit", value: "2,300", percentage: "+5%", percentageColor: "#48BB78", icon: language_icon },
    { title: "Number of Pharmacy", value: "+3,052", percentage: "-14%", percentageColor: "#E53E3E", icon: page_icon },
  ];

  return (
    <Box >
      {/* Header */}
      {/* <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
        <Box pt='16px'>
          <Box flexDirection='row' display='flex' alignItems='center' gap='3px'>
            <Typography variant="h6" sx={{ color: '#a0aec0', fontSize: '14px' }}>
              Pages
            </Typography>
            <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>/</Typography>
            <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>  Dashboard</Typography>
          </Box>
          <Typography mt={1} fontSize='14px' fontWeight='bold' color="#2D3748">Dashboard</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" alignItems="center" justifyContent="end" gap="16px" p={2} width="100%">
            <Box display="flex" alignItems="center" gap="16px">
              <Typography fontWeight="bold" fontSize="12px">From</Typography>
              <DatePicker
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                open={fromOpen} 
                onOpen={() => setFromOpen(true)} 
                onClose={() => setFromOpen(false)}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            setFromOpen(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <Box
                            component="img"
                            src={calenderImg}
                            alt="calendar_icon"
                            sx={{ width: '20px', height: '20px', objectFit: 'contain' }}
                          />
                        </InputAdornment>
                      ),
                    },
                    sx: {
                      color: 'white',
                      backgroundColor: '#71CA9B',
                      borderRadius: '15px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '15px',
                        color: 'white',
                        height: '39px',
                        width: '161px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                  },
                }}
              />
            </Box>

            <Box display="flex" alignItems="center" gap="16px">
              <Typography fontWeight="bold" fontSize="12px">To</Typography>
              <DatePicker
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                open={toOpen}
                onOpen={() => setToOpen(true)}
                onClose={() => setToOpen(false)}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            setToOpen(true);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <Box
                            component="img"
                            src={calenderImg}
                            alt="calendar_icon"
                            sx={{ width: '20px', height: '20px', objectFit: 'contain' }}
                          />
                        </InputAdornment>
                      ),
                    },
                    sx: {
                      backgroundColor: '#71CA9B',
                      borderRadius: '15px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '15px',
                        color: 'white',
                        height: '39px',
                        width: '161px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </LocalizationProvider>
        <Box display='flex' gap='20px' alignItems='center'>
          <Stack direction='row' alignItems={'baseline'} gap='3px'>
            <Box component={'img'} src={admin_icon} alt='user' sx={{ width: '12px', height: '12px' }} />
            <Typography fontSize='12px' fontWeight='bold' color='#718096'>Admin</Typography>
          </Stack>
          <Box component={'img'} src={settings_icon} alt='settings' sx={{ width: '12px', height: '12px' }} />
          <Box component={'img'} src={notification_icon} alt='notification' sx={{ width: '12px', height: '12px' }} />
        </Box>
      </Stack> */}
      <Grid container spacing={2} m={0} >
        {/* {data.map((item, index) => ( */}
        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important', paddingRight: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{ flexGrow: 1, p: 0 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0aec0', fontWeight: 'bold', fontSize: '14px' }}>Total Orders</Typography>
              <Box display='flex' gap='10px' alignItems='baseline'>
                <Typography variant="h5" fontSize='21px' color='#2D3748' fontWeight="bold">{dashStats.totalOrders || 0} </Typography>
                {/* <Typography variant="body2" sx={{ color: item.percentageColor, fontWeight: 'bold', fontSize: '16px' }}>
                    {item.percentage}
                  </Typography> */}
              </Box>
            </CardContent>
            <Box sx={{ width: '55px', height: '45px', backgroundColor: '#71CA9B', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component={'img'} src={document} />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important', paddingRight: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{ flexGrow: 1, p: 0 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0aec0', fontWeight: 'bold', fontSize: '14px' }}>Pending Orders</Typography>
              <Box display='flex' gap='10px' alignItems='baseline'>
                <Typography variant="h5" fontSize='21px' color='#2D3748' fontWeight="bold">{dashStats.pendingCount || 0} </Typography>
              </Box>
            </CardContent>
            <Box sx={{ width: '55px', height: '45px', backgroundColor: '#71CA9B', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component={'img'} src={language_icon} />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important', paddingRight: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{ flexGrow: 1, p: 0 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0aec0', fontWeight: 'bold', fontSize: '14px' }}>Completed Orders</Typography>
              <Box display='flex' gap='10px' alignItems='baseline'>
                <Typography variant="h5" fontSize='21px' color='#2D3748' fontWeight="bold">{dashStats.completedCount || 0} </Typography>
              </Box>
            </CardContent>
            <Box sx={{ width: '55px', height: '45px', backgroundColor: '#71CA9B', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component={'img'} src={page_icon} />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important', paddingRight: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{ flexGrow: 1, p: 0 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0aec0', fontWeight: 'bold', fontSize: '14px' }}>Order Value</Typography>
              <Box display='flex' gap='10px' alignItems='baseline'>
                <Typography variant="h5" fontSize='21px' color='#2D3748' fontWeight="bold">{dashStats.totalAmount || 0} </Typography>
              </Box>
            </CardContent>
            <Box sx={{ width: '55px', height: '45px', backgroundColor: '#71CA9B', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component={'img'} src={document} />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important', paddingRight: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{ flexGrow: 1, p: 0 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0aec0', fontWeight: 'bold', fontSize: '14px' }}>Gross Profit</Typography>
              <Box display='flex' gap='10px' alignItems='baseline'>
                <Typography variant="h5" fontSize='21px' color='#2D3748' fontWeight="bold">{dashStats.totalAmount || 0} </Typography>
              </Box>
            </CardContent>
            <Box sx={{ width: '55px', height: '45px', backgroundColor: '#71CA9B', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component={'img'} src={language_icon} />
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: '0 !important', paddingRight: '16px' }}>
          <Card sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', borderRadius: 2, boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{ flexGrow: 1, p: 0 }}>
              <Typography variant="subtitle2" sx={{ color: '#a0aec0', fontWeight: 'bold', fontSize: '14px' }}>Number of Pharmacy</Typography>
              <Box display='flex' gap='10px' alignItems='baseline'>
                <Typography variant="h5" fontSize='21px' color='#2D3748' fontWeight="bold">{dashStats.totalPharmacy || 0} </Typography>
              </Box>
            </CardContent>
            <Box sx={{ width: '55px', height: '45px', backgroundColor: '#71CA9B', borderRadius: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box component={'img'} src={page_icon} />
            </Box>
          </Card>
        </Grid>

        {/* ))} */}
      </Grid>
    </Box>
  );
};

export default DashboardOrderStats;
