import React, { useContext, useState } from 'react';
import {
  Box, Typography, TextField, Button, FormControlLabel, Grid,
  FormControl, Switch
} from '@mui/material';
import { CustomSwitch } from '../Atoms/CustomSwitch'; // Assuming you have a custom switch component
import { AuthContext } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { CustomToast } from '../Atoms/CustomToast';
import { ToastContainer } from 'react-toastify';

const LoginScreen = () => {
  const { getLoginCredentials, error, isSubmitting } = useContext(AuthContext); // Consume the context
  const [email, setEmail] = useState('');  // State for email input
  const [password, setPassword] = useState('');  // State for password input
  const navigate = useNavigate();  // Hook for navigation
  const { addToast } = CustomToast();

  // Handle login function
const handleLogin = async (e) => {
  e.preventDefault();

  console.log('Login function called');
  console.log('Email:', email);
  console.log('Password:', password);

  try {
    const success = await getLoginCredentials(email, password);
    console.log('Resolved Login Credentials:', success);

    if (success) {
      addToast({
        message: 'Login Successfully',
        type: 'success'
      });

      // Delay the navigation slightly to ensure the toast has time to display
      setTimeout(() => {
        navigate('/dashboard');
      }, 1000); // Adjust delay if necessary

    } else {
      console.log('Login failed');
    }
  } catch (error) {
    console.error('Error during login:', error);
  }
};
  console.log(email,'email')
  console.log(password,'password')

  return (
    <>
    <Grid container sx={{ height: '100vh' }} bgcolor={'#f9f9f9'}>
      <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#F7F8FA' }}>
        <Box sx={{ width: '80%', maxWidth: 400, textAlign: 'left' }}>
          <Typography variant="h4" sx={{ color: '#71ca9b', fontWeight: 'bold', mb: 1, fontSize: '32px' }}>
            Welcome Back
          </Typography>
          <Typography variant="subtitle1"
            sx={{ mb: '20px', color: '#A0AEC0', fontSize: '14px', fontWeight: 'bold' }}>
            Enter your email and password to sign in
          </Typography>

          {/* Login form */}
          <form onSubmit={handleLogin}>
            <FormControl sx={{ width: '100%', marginBottom: '20px' }}>
              <label style={{ fontFamily: 'Helvetica', fontSize: '14px', color: '#2D3748' }}>Email</label>
              <TextField
                sx={{
                  backgroundColor: 'white',
                  marginTop: '6px',
                  marginBottom: '8px',
                  borderRadius: '15px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#E2E8F0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#E2E8F0',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#E2E8F0',
                    },
                  },
                }}
                fullWidth
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"  // Ensure input type is 'email'
                autoComplete="text"  // Helps with browser autocomplete
              />
            </FormControl>

            <FormControl sx={{ width: '100%' }}>
              <label style={{ fontFamily: 'Helvetica', fontSize: '14px', color: '#2D3748' }}>Password</label>
              <TextField
                sx={{
                  backgroundColor: 'white',
                  marginTop: '6px',
                  marginBottom: '8px',
                  borderRadius: '15px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#E2E8F0',
                    },
                    '&:hover fieldset': {
                      borderColor: '#E2E8F0',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#E2E8F0',
                    },
                  },
                }}
                fullWidth
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your password"
                autoComplete="current-password"  // Helps with browser autocomplete
              />
            </FormControl>

            {/* Remember me switch */}
            <FormControlLabel
              control={<CustomSwitch />}
              label="Remember me"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '12px',
                  marginLeft: '12px',
                  color: '#2D3748'
                },
                mt: 2,
                ml: 0
              }}
            />

            {/* Login button */}
            <Button
              variant="contained"
              fullWidth
              sx={{
                mt: 3, mb: 2, py: 1, fontWeight: 'bold', height: '45px',
                fontSize: '12px', backgroundColor: "#71CA9B",
                borderRadius: '12px', boxShadow: 'none',
                fontFamily: 'Helvetica', '&:hover': { boxShadow: 'none' }
              }}
              type="submit"
              disabled={isSubmitting}  // Disable the button while submitting
            >
              SIGN IN
            </Button>
          </form>

          {/* Error message */}
          {error && <Typography color="error" sx={{ textAlign: 'center' }}>{error}</Typography>}
        </Box>
      </Grid>

      {/* Right section (background image/logo) */}
      <Grid className="anahad_bg_img" item xs={12} md={5} sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: '15px',
        borderBottomLeftRadius: '15px'
      }}>
        <Box sx={{ textAlign: 'center', color: 'white', p: 4 }}>
          <img src="logo.png" alt="Logo" style={{ width: '214px', marginBottom: '1rem' }} />
        </Box>
      </Grid>
    </Grid>
    <ToastContainer/>
    </>
  );
};

export default LoginScreen;
