const BASE_URL = `${process.env.REACT_APP_BASE_URL}`

const Config = {
    Login_Url:  `${BASE_URL}login`,
    Dashboard_details: `${BASE_URL}dashboard-details`,
    Sales_Overview: `${BASE_URL}sales_overview`,
    Get_Orders: `${BASE_URL}getorders`,
    Get_Order_Details: `${BASE_URL}getOrderDetails`,
    Get_Pharmacies_list: `${BASE_URL}getPharmacies`,
    Get_Order_Status_Graph: `${BASE_URL}dashboard_graph`,
    Logout: `${BASE_URL}logout`
}

export default Config;