import { Box, Typography } from "@mui/material";
import React from "react";
import banner_img from "../../images/website-images/Frame.png"
import banner_img_responsive from "../../images/website-images/frame_responsive.png"

const Banner = () => {
    return (
        <>
            <Box display='flex'
                flexDirection={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                paddingBottom={{ xs: '34px', sm: '42px', md: 'unset' }}
                flexWrap='wrap' height={{ xs: '676px', sm: '676px', md: '618px' }}
                alignItems='center' position='relative'>
                <Typography
                    className="banner_heading"
                    sx={{
                        fontSize: { xs: '38px', sm: '40px', md: '60px' },
                        lineHeight: { xs: '50px', sm: '50px', md: '65px' },
                        position: { md: 'absolute' },
                        width: { xs: '88%', sm: '88%', md: '38%' },
                        marginLeft: { xs: '0px', sm: "0px", md: 'unset' },
                        bottom: { xs: '-10px', md: '-50px' },
                        letterSpacing: '-1.5px'
                    }}
                    style={{
                        color: 'transparent',
                        background: 'linear-gradient(244deg, #000000, #5e5e54)',
                        backgroundClip: 'text',
                        fontFamily: "'Kamerik205Regular3', sans-serif",
                        WebkitBackgroundClip: 'text', height: 'fit-content', left: '111px',
                    }}>Your Trusted <br /> Partner in <br /> Medicine Supply</Typography>
                {/* <Box component={'img'}
                    src={banner_img} alt="banner"
                    width={{ md: '68%' }} height={{ xs: '520px', sm: '520px', md: '846px' }}
                    position='absolute' right={{ xs: '0px', md: '0px' }}
                    top={{ xs: '-10px', sm: '-10px', md: '-55px' }} /> */}
                <Box component={'img'}
                    src={banner_img} alt="banner" display={{ xs: 'none', sm: 'none', md: 'block' }}
                    width={{ md: '64%' }} height={{ md: '846px' }}
                    position='absolute' right={{ xs: '0px', md: '73px' }}
                    top={{ xs: '-10px', sm: '-10px', md: '-30px' }} />

                <Box component={'img'} src={banner_img_responsive} display={{ xs: 'block', sm: 'block', md: 'none' }} position={'absolute'} left='0px' width='100%' top='-15px' height={'620px'} />
            </Box>
        </>
    )
}

export default Banner