import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import blankImg from "../../images/website-images/Rectangle.png"

const WhatWeDo = () => {
    return (
        <>
        <Box sx={{background: 'radial-gradient(circle at top left, #ebe1ca36, #efebe32b 18%, rgba(255, 255, 255, 0.5) 28%)'}}>
            <Box pt={{xs: '58px', sm: '4rem',md:'4rem'}} width={{xs: '86%',md:'78%'}} margin='auto' mb={{xs: '3rem', sm: '6rem',md:'6rem'}}>
                <Typography color="#3A3F3C" fontSize='36px' fontWeight='600' textAlign='center' fontFamily="'GeneralSansSemibold', sans-serif">What We Do</Typography>
                <Typography textAlign='center' color="#3C4959" fontSize='15px' mt={{xs: '10px', sm: '1rem',md:'1rem'}} fontFamily= "'GeneralSansRegular', sans-serif">Revolutionizing pharmacy supply chains with seamless, efficient, and <br /> tech-driven solutions.</Typography>

                <Stack marginTop={{xs: '38px', md:'3rem'}} direction={{xs: 'column', sm: 'row', md:'row'}} justifyContent='space-between' >
                    <Box component={'img'} src={blankImg} alt="blank" width={{md:'550px'}} height={{md: '305px'}}/>
                    <Box width={{xs: '91%',sm: '52%',md: '52%'}} marginLeft={{md: '30px'}}>
                    <Typography display={{xs: 'none', sm: 'block', md: 'block'}} color="#3A3F3C" fontSize='24px' fontFamily="'GeneralSansMedium', sans-serif"letterSpacing='0px'>Empowering Pharmacies, Elevating Healthcare</Typography>
                    <Typography display={{xs: 'block', sm: 'none', md: 'none'}} color="#3A3F3C" fontSize='22px' fontFamily="'GeneralSansMedium', sans-serif"letterSpacing='0px' lineHeight='28px' marginTop='2rem'>Empowering Pharmacies, <br/> Elevating Healthcare</Typography>
                    <Typography color="#3C4959" fontSize='15px' fontWeight='lighter' mt='14px' fontFamily="'GeneralSansRegular', sans-serif">
                        At Anahad, we are transforming the pharmaceutical supply chain to meet the evolving 
                        needs of local pharmacies. We serve as a dependable partner for thousands of
                        pharmacies, providing access to an extensive portfolio of trusted brands and products.</Typography>

                    <Typography color="#3C4959" fontSize='15px' fontWeight='lighter' mt='14px' fontFamily="'GeneralSansRegular', sans-serif"> 
                        We go beyond being just a supplier. Our technology-driven approach empowers 
                        pharmacies to stay ahead in a competitive market. By offering seamless inventory 
                        management, real-time tracking, and exceptional customer support. </Typography>

                    <Typography color="#3C4959" fontSize='15px' fontWeight='lighter' mt='14px' fontFamily="'GeneralSansRegular', sans-serif"> 
                        With a vision to strengthen the backbone of healthcare, we work tirelessly to bridge the 
                        gap between warehouses and pharmacies, fostering trust and efficiency at every step. 
                        At Anahad, we don’t just deliver medicines—we deliver solutions, reliability, and a 
                        promise to support pharmacies as they continue to serve their communities.
                    </Typography>
                    </Box>
                </Stack>
            </Box>
            </Box>
        </>
    )
}

export default WhatWeDo