import React, { useState } from 'react';
import {
  Typography, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Button, Grid,
  Box
} from '@mui/material';
import { Link } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import OrdersList from '../components/OrderList';
import Sidebar from '../components/Sidebar';

const orderData = [
  { id: 101, pharmacy: 'Pharmacy One', orderDate: '2024-11-01', deliveryDate: '2024-11-05', status: 'Pending', total: 250 },
  { id: 102, pharmacy: 'Pharmacy Two', orderDate: '2024-11-02', deliveryDate: '2024-11-06', status: 'Completed', total: 500 },
  { id: 103, pharmacy: 'Pharmacy Three', orderDate: '2024-11-03', deliveryDate: '2024-11-07', status: 'Cancelled', total: 150 },
  // Additional dummy data
];

const Orders = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredOrders, setFilteredOrders] = useState(orderData);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDateChange = (index, newDate) => {
    const newRange = [...dateRange];
    newRange[index] = newDate;
    setDateRange(newRange);
  };

  const filterOrders = () => {
    let filtered = orderData;

    if (searchTerm) {
      filtered = filtered.filter(order =>
        order.pharmacy.toLowerCase().includes(searchTerm)
      );
    }

    if (dateRange[0] && dateRange[1]) {
      const startDate = dayjs(dateRange[0]);
      const endDate = dayjs(dateRange[1]);
      filtered = filtered.filter(order => {
        const orderDate = dayjs(order.orderDate);
        return orderDate.isAfter(startDate) && orderDate.isBefore(endDate);
      });
    }

    setFilteredOrders(filtered);
  };

  React.useEffect(() => {
    filterOrders();
  }, [searchTerm, dateRange]);

  return (
    // <div>
    //   <Typography variant="h4" gutterBottom>
    //     Orders
    //   </Typography>

    //   {/* Filters */}
    //   <Grid container spacing={2} style={{ marginBottom: '20px' }}>
    //     <Grid item xs={12} md={4}>
    //       <TextField
    //         fullWidth
    //         label="Search by Pharmacy"
    //         variant="outlined"
    //         value={searchTerm}
    //         onChange={handleSearchChange}
    //       />
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       <LocalizationProvider dateAdapter={AdapterDayjs}>
    //         <DatePicker
    //           label="Start Date"
    //           value={dateRange[0]}
    //           onChange={(newDate) => handleDateChange(0, newDate)}
    //           renderInput={(params) => <TextField {...params} fullWidth />}
    //         />
    //       </LocalizationProvider>
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       <LocalizationProvider dateAdapter={AdapterDayjs}>
    //         <DatePicker
    //           label="End Date"
    //           value={dateRange[1]}
    //           onChange={(newDate) => handleDateChange(1, newDate)}
    //           renderInput={(params) => <TextField {...params} fullWidth />}
    //         />
    //       </LocalizationProvider>
    //     </Grid>
    //   </Grid>

    //   {/* Orders Table */}
    //   <TableContainer component={Paper}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>ID</TableCell>
    //           <TableCell>Pharmacy</TableCell>
    //           <TableCell>Order Date</TableCell>
    //           <TableCell>Status</TableCell>
    //           <TableCell>Total ($)</TableCell>
    //           <TableCell>Actions</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {filteredOrders.map((order) => (
    //           <TableRow key={order.id}>
    //             <TableCell>{order.id}</TableCell>
    //             <TableCell>{order.pharmacy}</TableCell>
    //             <TableCell>{order.orderDate}</TableCell>
    //             <TableCell>{order.status}</TableCell>
    //             <TableCell>{order.total}</TableCell>
    //             <TableCell>
    //               <Button
    //                 variant="contained"
    //                 color="primary"
    //                 component={Link}
    //                 to={`/orderdetails/${order.id}`}
    //               >
    //                 View
    //               </Button>
    //             </TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // </div>
    <>
    <Box style={{ display: 'flex', padding: '0px 20px 20px 10px' }}>
      <Sidebar/>
    <Box sx={{ px: '16px', width: '100%' }}>
      <OrdersList/>
    </Box>
    </Box>
    </>
  );
};

export default Orders;
