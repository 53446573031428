import React, { useContext, useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
    LinearProgress,
    Grid,
    IconButton,
    TextField,
    InputAdornment,
    Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import calenderImg from '../images/uis_calender.png'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import searchIcon from '../images/search-icon.png'
import arrowImg from '../images/arrowicon.png'
import OrderStatusProgress from "../Atoms/StepProgressbar";
import ploygenImg from "../images/Polygon.png"
import { Link } from "react-router-dom";
import admin_icon from '../images/person.png'
import settings_icon from '../images/settings.png'
import notification_icon from '../images/notification.png'
import axios from "axios";
import Config from "../utils/Config";
import { AuthContext } from "../Context/AuthContext";
import moment from "moment/moment";

const getStatusChip = (status) => {
    if (status === "Order Delivered")
        return <Chip label="Completed" style={{ backgroundColor: "#66BB6A", color: "#fff", width: '96px', height: '25px', borderRadius: '8px' }} />;
    if (status === "Out for Delivery" || status === "Bill Generation" || status === "Preparing Dispatch" || status === "Goods Dispatch")
        return <Chip label="Pending" style={{ backgroundColor: "#CBD5E0", color: "#fff", width: '96px', height: '25px', borderRadius: '8px' }} />;
    if (status === "Cancelled")
        return <Chip label="Cancelled" style={{ backgroundColor: "#E01E38", color: "#fff", width: '96px', height: '25px', borderRadius: '8px' }} />;
};

const OrdersList = () => {

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [orderData, setOrderData] = useState([]);
    const [fromOpen, setFromOpen] = useState(false);
    const { apiToken } = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDateChange = (newDate) => {
        if (newDate) {
            const formattedDate = newDate.format("YYYY-MM-DD");
            setSelectedDate(formattedDate);
        }
        setFromOpen(false);
    };

    const Get_order_List = async () => {
        try {
            const response = await axios.post(Config?.Get_Orders, {
                date: selectedDate,
                page: '1',
                search: ''
            }, {
                headers: {
                    Authorization: `Bearer ${apiToken}`
                }
            })
            if (response?.status === 200) {
                setOrderData(response?.data?.apiData);
                console.log(response?.data?.apiData, 'OrderList')
            } else {
                console.log('Something went wrong')
            }
        } catch (error) {
            console.log(error, 'Error in fetching Response..')
        }
    }

    const filteredOrders = orderData.filter(order => {
        return (
            order.invoiceNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.pharmacyName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    useEffect(() => {
        Get_order_List()
    }, [selectedDate, searchQuery]);

    const handleSortOrderValue = () => {
        const sortedOrders = [...orderData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.totalAmount - b.totalAmount; // Sort low to high
            } else {
                return b.totalAmount - a.totalAmount; // Sort high to low
            }
        });
        setOrderData(sortedOrders); // Update sorted data
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order
    };

    return (
        <Box>
            {/* Top Bar */}
            <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
                <Box pt='16px'>
                    <Box flexDirection='row' display='flex' alignItems='center' gap='3px'>
                        <Typography variant="h6" sx={{ color: '#a0aec0', fontSize: '14px' }}>
                            Pages
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>/</Typography>
                        <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>  Orders</Typography>
                    </Box>
                    <Typography mt={1} fontSize='14px' fontWeight='bold' color="#2D3748">Orders</Typography>
                </Box>
                <Box p={'16px'} display='flex' gap='20px'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display="flex" alignItems="center" gap='16px'>
                            <DatePicker
                                value={dayjs(selectedDate)}
                                onChange={handleDateChange}
                                open={fromOpen}
                                onOpen={() => setFromOpen(true)}
                                onClose={() => setFromOpen(false)}
                                slots={{
                                    textField: TextField,
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        InputProps: {
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setFromOpen(true);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={calenderImg}
                                                        alt="calendar_icon"
                                                        sx={{
                                                            width: "20px",
                                                            height: "20px",
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        },
                                        sx: {
                                            color: "white",
                                            backgroundColor: "#71CA9B",
                                            borderRadius: "15px",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "15px",
                                                color: "white",
                                                height: "39px",
                                                width: "161px",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </LocalizationProvider>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            background: "#ffffff",
                            padding: "5px 10px",
                            borderRadius: "15px",
                            border: '1px solid #E2E8F0',
                            width: '250px'
                        }}>
                        <Box component={'img'} src={searchIcon} />
                        <input
                            placeholder="Search Pharmacy"
                            value={searchQuery} // Bind search query to input
                            onChange={handleSearchChange} // Handle input change
                            style={{
                                border: "none",
                                background: "transparent",
                                marginLeft: "10px",
                                outline: "none",
                                color: 'gray.400'
                            }}
                        />
                    </Box>
                    <Box display='flex' gap='20px' alignItems='center'>
                        <Stack direction='row' alignItems={'baseline'} gap='3px'>
                            <Box component={'img'} src={admin_icon} alt='user' sx={{ width: '12px', height: '12px' }} />
                            <Typography fontSize='12px' fontWeight='bold' color='#718096'>Admin</Typography>
                        </Stack>
                        <Box component={'img'} src={settings_icon} alt='settings' sx={{ width: '12px', height: '12px' }} />
                        <Box component={'img'} src={notification_icon} alt='notification' sx={{ width: '12px', height: '12px' }} />
                    </Box>
                </Box>
            </Stack>

            {/* Main Content */}
            <Box sx={{ marginTop: "20px", backgroundColor: '#ffffff', p: '24px', borderRadius: '15px' }}>
                <Typography variant="h5" sx={{ marginBottom: "10px", fontWeight: 'bold', fontSize: '18px', color: '#2D3748', pl: '15px' }}>
                    Pharmacy Orders
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableheadcell" sx={{width: '11%'}}>Invoice Number</TableCell>
                                <TableCell className="tableheadcell" sx={{ width: "26%" }}>Pharmacy Name</TableCell>
                                <TableCell className="tableheadcell">Order Date</TableCell>
                                <TableCell className="tableheadcell" sx={{width: '13%'}}>Status</TableCell>
                                <TableCell className="tableheadcell">Completion</TableCell>
                                <TableCell className="tableheadcell">Order Value <span onClick={handleSortOrderValue}
                                    style={{ cursor: 'pointer', userSelect: 'none' }}><Box component={'img'} src={ploygenImg} alt="filter-arrow" ml='3px' sx={{
                                        transform: sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                        transition: 'transform 0.2s'
                                    }} /></span></TableCell>
                                <TableCell className="tableheadcell"></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Box sx={{maxHeight: '410px', overflowY: 'auto',  '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#d9d9d9',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#4A5568',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#fff',
                },}}>
                        <Table>
                            <TableBody>
                                {filteredOrders.map((row, index) => (
                                    <TableRow key={index} sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}>
                                        <TableCell className="tablerowcell" sx={{width: '11%'}}>{row.invoiceNumber}</TableCell>
                                        <TableCell className="tablerowcell" sx={{ width: "26%" }}>
                                            {row.pharmacyName}
                                            <Typography fontSize='14px' sx={{ color: '#718096' }} variant="body2" color="textSecondary">
                                                {row.address}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className="tablerowcell">{moment(row.orderDate).format('DD/MM/YY')}</TableCell>
                                        <TableCell className="tablerowcell"sx={{width: '13%'}} >{getStatusChip(row.orderStatus)}</TableCell>
                                        <TableCell className="tablerowcell" sx={{width: '19%'}}>
                                            {/* <Box >
                                            <Typography variant="body2">{`${row.completion}%`}</Typography>
                                            <LinearProgress
                                                variant="determinate"
                                                value={row.completion}
                                                color="success"
                                                sx={{ flexGrow: 1, marginRight: 1, height: "8px", borderRadius: "4px" }}
                                            />
                                        </Box> */}
                                            <OrderStatusProgress status={row?.orderStatus} />
                                        </TableCell>
                                        <TableCell className="tablerowcell" sx={{ width: "10%" }}>{row.totalAmount}</TableCell>
                                        <TableCell>
                                            <Link to={`/orderdetails/${row.orderId}`}>
                                                <Box component={'img'} src={arrowImg} alt="rightArrow" />
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default OrdersList;
