import { Box, Button, Typography } from "@mui/material";
import React from "react";
import video_dummy from "../../images/website-images/video_vector.png"
import video_mask from "../../images/website-images/mask_group.png"

const VideoContainer = () => {
    return (
        <>
            <Box margin={{xs: '58px 0rem 58px 24px',md:'6rem 0rem 4rem'}} width={{xs: '88%', sm: 'unset'}}>
                <Typography color="#3A3F3C" display={{xs: 'none', sm: 'block', md: 'block'}}
                    fontSize={{ xs: '30px', sm: '30px', md: "30px" }}
                    textAlign="center"
                    mb="1rem"
                    sx={{ fontFamily: "'GeneralSansSemibold', sans-serif" }}
                    lineHeight='38px'
                    textTransform='capitalize'
                    letterSpacing="-0.4px">
                    We’re excited to partner with new <br /> pharmacies and support their growth.
                </Typography>
                <Typography color="#3A3F3C" display={{xs: 'block', sm: 'none', md: 'none'}}
                    fontSize={{ xs: '30px', sm: '30px', md: "30px" }}
                    textAlign="left"
                    mb="1rem"
                    sx={{ fontFamily: "'GeneralSansSemibold', sans-serif" }}
                    lineHeight='38px'
                    textTransform='capitalize'
                    letterSpacing="-0.4px">
                    We’re excited to <br/> partner with new <br /> pharmacies and <br/> support their growth.
                </Typography>
                <Typography display={{xs: 'none', sm: 'block', md: 'block'}}
                    color='#3C4959' textAlign='center'
                    sx={{
                        fontFamily: "'GeneralSansRegular', sans-serif",
                        fontSize: { xs: '15px', sm: '15px', md: '15px' }
                    }}
                >We ensure top-quality sourcing and reliable service to <br /> deliver the best for your pharmacy.
                </Typography>
                <Typography display={{xs: 'block', sm: 'none', md: 'none'}}
                    color='#3C4959' textAlign='left'
                    sx={{
                        fontFamily: "'GeneralSansRegular', sans-serif",
                        fontSize: { xs: '15px', sm: '15px', md: '15px' }
                    }}
                >We ensure top-quality sourcing and reliable <br/> service to deliver the best for your  <br /> pharmacy.
                </Typography>
                <Box textAlign='center' marginTop='3rem' marginBottom='2rem' display={{xs: 'none',sm: 'block', md: 'block'}}>
                    <Box component={'img'} src={video_dummy} alt="" width='58%' />
                </Box>
                <Box textAlign='center' marginTop='2rem' marginBottom='1.5rem' display={{xs: 'block',sm: 'none', md: 'none'}}>
                    <Box component={'img'} src={video_mask} alt="" width='100%'/>
                </Box>
                <Box textAlign='center'>
                <Button style={{
                    backgroundColor: '#53A57A',
                    color: 'white', padding: '8px 20px',
                     borderRadius: '8px',
                    fontSize: '13px', textTransform: 'capitalize'
                }}
                    sx={{ fontFamily: "'GeneralSansRegular', sans-serif" }}>Connect</Button>
                    </Box>
            </Box>
        </>
    )
}

export default VideoContainer