import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography, Box, Paper, Card, CardContent, Divider,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  LinearProgress, Stack,
  Button,
  IconButton,
  Avatar,
  TableFooter
} from '@mui/material';
import anahad_background_img from "../images/Background.png"
import overview_icon from "../images/overview_icon.png"
import DeliveryProgressTracker from '../Atoms/OrderDeliveryProgressbar';
import check_img from "../images/circle-check.png";
import searchIcon from '../images/search-icon.png'
import Sidebar from '../components/Sidebar';
import axios from 'axios';
import Config from '../utils/Config';
import { AuthContext } from '../Context/AuthContext';
import moment from 'moment';
import admin_icon_white from '../images/user_icon_white.png';
import settings_icon_white from '../images/setting_icon_white.png';
import bell_icon_white from '../images/bell_icon_white.png';

const getOrderStatusProgress = (status) => {
  switch (status) {
    case 'Pending': return 25;
    case 'Processing': return 50;
    case 'Shipped': return 75;
    case 'Completed': return 100;
    default: return 0;
  }
};

const OrderDetails = () => {


  const { orderId } = useParams();
  const { apiToken } = useContext(AuthContext)
  // console.log(typeof (orderId), 'type')
  const [orderDetails, setOrderDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const tableRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleViewMore = () => {
    // setIsExpanded((prev) => !prev); 
    setIsExpanded(true)
  };

  const GetOrderDetails = async () => {
    try {
      const response = await axios.get(Config?.Get_Order_Details, {
        params: {
          orderId: orderId
        },
        headers: {
          Authorization: `Bearer ${apiToken}`
        }
      })
      if (response?.status === 200) {
        setOrderDetails(response?.data?.apiData);
        console.log(response?.data?.apiData, 'orderDetails')
        console.log(response?.data?.apiData?.invoiceNumber, 'orderInvoicenumbers')
      } else {
        console.log('Something went wrong')
      }
    } catch (error) {
      console.log(error, 'Error in Fetching API response..')
    }
  }
  useEffect(() => {
    GetOrderDetails()
  }, [orderId, apiToken]);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const period = hours >= 12 ? 'P.M.' : 'A.M.';
    hours = hours % 12 || 12;
    return `${hours} ${period}`;
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on the search query
  const filteredItems = orderDetails?.orderitems?.filter((item) =>
    item?.itemName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box display='flex' padding='0px 20px 20px 10px'>
      <Sidebar />
      <Box p={'16px'} width={'100%'} >
        <Box width='100%' position='relative'>
          <Box
            sx={{
              backgroundImage: `url(${anahad_background_img})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: '200px',
              width: '100%',
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              position: "relative",
            }}>
            {/* Header navigation */}
            <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
              <Box>
                <Typography variant="caption" color="white">
                  Pages / Order
                </Typography>
                <Typography variant="h5" color="white" fontWeight="bold" mt={1}>
                  Order details
                </Typography>
              </Box>
              <Box display='flex' gap='12px'>
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    background: "#ffffff",
                    padding: "12px 10px",
                    borderRadius: "15px",
                    border: '1px solid #E2E8F0',
                    width: '199px'
                  }}>
                  <Box component={'img'} src={searchIcon} />
                  <input
                    placeholder="Search Pharmacy"
                    // value={searchQuery} // Bind search query to input
                    // onChange={handleSearchChange} // Handle input change
                    style={{
                      border: "none",
                      background: "transparent",
                      marginLeft: "10px",
                      outline: "none",
                      color: 'gray.400'
                    }}
                  />
                </Box> */}
                {/* Sign in and settings icons */}
                <Box display='flex' gap='20px' alignItems='center'>
                  <Stack direction='row' alignItems={'baseline'} gap='3px'>
                    <Box component={'img'} src={admin_icon_white} alt='user' sx={{ width: '12px', height: '12px' }} />
                    <Typography fontSize='12px' fontWeight='bold' color='#fff'>Admin</Typography>
                  </Stack>
                  <Box component={'img'} src={settings_icon_white} alt='settings' sx={{ width: '12px', height: '12px' }} />
                  <Box component={'img'} src={bell_icon_white} alt='notification' sx={{ width: '12px', height: '12px' }} />
                </Box>
              </Box>
            </Stack>
          </Box>

          {/* White card section */}
          <Card
            sx={{
              marginX: "30px",
              padding: "0px 16px",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: '113px',
              boxShadow: 'none',
              position: 'absolute',
              top: '166px',
              opacity: '0.9',
              width: '95%'
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              {/* Placeholder for logo */}
              <Avatar
                sx={{ width: 64, height: 64, backgroundColor: "#eee" }}
                variant="rounded"
              >
                {/* Logo or icon can go here */}
              </Avatar>
              <Box>
                <Typography variant="h6" fontSize='18px' color='#2D3748' fontWeight='bold'>{orderDetails?.pharmacy?.name}</Typography>
                <Typography variant="body2" color="#718096" fontSize='14px'>
                  {orderDetails?.pharmacy?.address}
                </Typography>
              </Box>
            </Box>

            {/* Overview button */}
            <Box sx={{ backgroundColor: '#ffffff !important' }}>
              <Button color="#2D3748" sx={{ backgroundColor: 'white !important', padding: '10px 25px', borderRadius: "12px", fontSize: '10px', fontWeight: 'bold', color: '#2D3748', gap: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06)' }}>
                <Box component={'img'} src={overview_icon} alt='Overicon' sx={{ width: '11px', height: '11px' }} />
                Overview
              </Button>
            </Box>
          </Card>
        </Box>
        <Box display='flex' width='100%' flexWrap='wrap' mt={'5rem'} justifyContent='space-between'>
          <Box width='59%'>
            <Stack direction="row" spacing={2}>
              {/* Pharmacy Info Card */}
              <Card sx={{ minWidth: 275, flex: 1, borderRadius: '15px', boxShadow: '1px 2px 10px #f1f1f1' }}>
                <CardContent sx={{ padding: '20px 35px 24px' }}>
                  <Typography variant="h6" color="#48505c" textTransform='uppercase' fontSize='14px' fontWeight='bold' marginBottom='15px' gutterBottom>
                    Pharmacy Details
                  </Typography>
                  {/* <Divider sx={{ my: 1 }} /> */}
                  <Box display='flex' flexDirection='column' gap='13px' ml='12px'>
                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Full Name: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{orderDetails?.pharmacy?.name}</span></Typography>
                    <Typography variant="body1" ><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Invoice Number: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}> {orderDetails?.invoiceNumber} </span></Typography>
                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Contact: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{orderDetails?.pharmacy?.contactNumber} </span></Typography>
                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Address: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{orderDetails?.pharmacy?.address}</span></Typography>
                  </Box>
                </CardContent>
              </Card>

              {/* Order Info Card */}
              <Card sx={{ minWidth: 275, flex: 1, borderRadius: '15px', boxShadow: '1px 2px 10px #f1f1f1' }}>
                <CardContent sx={{ padding: '20px 35px 24px' }}>
                  <Typography variant="h6" color="#48505c" textTransform='uppercase' fontSize='14px' fontWeight='bold' marginBottom='15px' gutterBottom>
                    Order Details
                  </Typography>
                  {/* <Divider sx={{ my: 1 }} /> */}
                  <Box display='flex' flexDirection='column' gap='13px'>
                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Order Date:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{moment(orderDetails?.orderDate).format('DD/MM/YY')} </span></Typography>
                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Order Time:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}> {formatTime(orderDetails.orderDate)} </span></Typography>
                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Delivery Date:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{moment(orderDetails.deliveryDate).format('DD/MM/YY')} </span></Typography>
                    <Typography variant="body1"> <strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Status:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{orderDetails.orderStatus}</span></Typography>
                  </Box>
                  {/* <Box mt={2}>
                <Typography>Status Progress</Typography>
                <LinearProgress
                  variant="determinate"
                  value={getOrderStatusProgress(orderDetails.status)}
                  color="primary"
                  sx={{ height: 8, borderRadius: 5 }}
                />
              </Box> */}
                </CardContent>
              </Card>
            </Stack>
            <Box mt='24px'>
              <Card sx={{ borderRadius: '15px', boxShadow: '1px 2px 10px #f1f1f1' }}>
                <CardContent sx={{ padding: '20px 22px 24px' }}>
                  <Typography variant="h6" color="#48505c" textTransform='uppercase' fontSize='14px' fontWeight='bold' marginBottom='1.5rem' gutterBottom>
                    Order Status
                  </Typography>
                  <Box>
                    <DeliveryProgressTracker />
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>

          {/* Order Items Table */}
          <Box width='36%' sx={{ background: 'white', padding: '20px 20px 24px', borderRadius: '15px', boxShadow: '1px 2px 10px #f1f1f1', height: 'fit-content' }}>
            <Stack direction='row' justifyContent='space-between'>
              <Box>
                <Typography fontWeight='bold' fontSize='18px' color='#2D3748' gutterBottom>Order Items</Typography>
                <Stack direction='row' alignItems='center' gap='5px'>
                  <Box component={'img'} src={check_img} alt='check-icon' width={15} height={15} />
                  <Typography fontSize='14px' fontWeight='bold' color='#A0AEC0'>30 done</Typography>
                  <Typography fontSize='14px' fontWeight='normal' color='#A0AEC0'>this month</Typography>
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "#ffffff",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  border: '1px solid #E2E8F0',
                  width: '190px',
                  height: '28px'
                }}>
                <Box component={'img'} src={searchIcon} />
                <input
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search Pharmacy"
                  style={{
                    border: "none",
                    background: "transparent",
                    marginLeft: "10px",
                    outline: "none",
                    color: 'gray.400'
                  }} />
              </Box>
            </Stack>
            <TableContainer>
              {/* Table Head */}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: '#A0AEC0',
                        borderBottom: 'none',
                      }}
                    >
                      <strong>Items</strong>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: '#A0AEC0',
                        textAlign: 'center',
                        borderBottom: 'none',
                      }}
                    >
                      <strong>Quantity</strong>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: '#A0AEC0',
                        textAlign: 'center',
                        borderBottom: 'none',
                      }}
                    >
                      <strong>Value</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              {/* Scrollable Body and Footer */}
              <Box
               sx={{
                maxHeight: isExpanded ? 'none' : '215px', // Full height if expanded
                overflowY: isExpanded ? 'unset' : 'auto',  // Disable scroll if expanded
                width: '100%',
                '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#d9d9d9',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#4A5568',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#fff',
                },
              }}
              >
                <Table>
                  <TableBody>
                    {filteredItems?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#2d3748',
                            borderBottom: '1px solid #E2E8F0',
                          }}
                        >
                          {item?.itemName}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#2d3748',
                            textAlign: 'center',
                            borderBottom: '1px solid #E2E8F0',
                          }}
                        >
                          {item?.quantity}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            color: '#2d3748',
                            textAlign: 'center',
                            borderBottom: '1px solid #E2E8F0',
                          }}
                        >
                          {item?.totalAmount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: '10px',
                          fontWeight: 'bold',
                          color: '#A0AEC0',
                          borderBottom: 'none',
                        }}
                      >
                        Value
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#2d3748',
                          textAlign: 'center',
                          borderBottom: 'none',
                        }}
                      >
                        {filteredItems?.reduce((total, item) => total + item?.quantity, 0)}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#2d3748',
                          textAlign: 'center',
                          borderBottom: 'none',
                        }}
                      >
                        {filteredItems?.reduce((total, item) => total + item?.totalAmount, 0).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Box>
            </TableContainer>
            {!isExpanded && (
              <Box textAlign='end' padding='0px 16px' marginTop='10px'>
              <Button
                onClick={handleViewMore}
                sx={{
                  fontSize: '10px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: '#687688',
                  borderColor: 'transparent',
                  padding: '0px 35px',
                }}
              >
                View More
              </Button></Box>)}
          </Box>

          {/* Total Amount */}
          {/* <Box mt={3} textAlign="right">
          <Paper variant="outlined" sx={{ padding: 2, display: 'inline-block', minWidth: '200px' }}>
            <Typography variant="body1"><strong>Total Amount:</strong> ${orderDetails.total}</Typography>
          </Paper>
        </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderDetails;
