import { Box, Typography } from "@mui/material";
import React from "react";
import brandImg1 from "../../images/website-images/wallace.png";
import brandImg2 from "../../images/website-images/ipca.png";
import brandImg3 from "../../images/website-images/cipla.png";
import brandImg4 from "../../images/website-images/abbott.png";
import brandImg5 from "../../images/website-images/viatris-preview.png";
import brandImg6 from "../../images/website-images/RB-preview.png";
import brandImg7 from "../../images/website-images/torrent.png";
import brandImg8 from "../../images/website-images/indoco-preview.png";
import brandImg9 from "../../images/website-images/mankind.png";

const brands = [
    { src: brandImg1, width: "145px", width_s: '90px'},
    { src: brandImg2, width: "130px",  width_s: '90px' },
    { src: brandImg3, width: "100px",  width_s: '70px' },
    { src: brandImg4, width: "120px",  width_s: '100px' },
    { src: brandImg5, width: "95px",  width_s: '100px' },
    { src: brandImg6, width: "80px",  width_s: '50px' },
    { src: brandImg7, width: "155px",  width_s: '90px' },
    { src: brandImg8, width: "160px" ,  width_s: '80px'},
    { src: brandImg9, width: "60px",  width_s: '80px' },
];

const AffiliatedBrandsResponsive = () => {
    return (
        <Box mt="3rem">
            <Typography
                color="#3A3F3C"
                fontSize={{xs: '30px', sm: '32px',md:"32px"}}
                textAlign="center"
                mb="3rem"
                sx={{ fontFamily: "'GeneralSansSemibold', sans-serif" }}
                letterSpacing="-0.6px"
            >
                Brands represented
            </Typography>
            <Box
                display="flex"
                flexWrap="wrap"
                width={{ xs: "86%", sm: "92%", md: "74%" }}
                justifyContent="space-between"
                gap="5px"
                margin="auto"
                marginTop="20px"
            >
                {brands.map((brand, index) => (
                    <Box
                        key={index}
                        width={{
                            xs: "27%", // For small devices (mobile)
                            sm: "28%", // For tablets
                            md: "20%", // For desktops
                            lg: "15%", // For larger screens
                        }}
                        mb="30px"

                        textAlign="center"
                        // sx={{
                        //     display: index === 5 ? { xs: "none", sm: "none", md: "block" } : "block", // Hide brandImg6 on small and medium screens
                        // }}
                    >
                        <Box
                            component="img"
                            sx={{ width: {xs: brand?.width_s, sm: brand?.width_s ,md:brand.width} }} // Dynamic width
                            src={brand.src}
                            alt={`Brand ${index + 1}`}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default AffiliatedBrandsResponsive;
