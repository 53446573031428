import { Box, Stack, Typography, Divider } from "@mui/material";
import React from "react";

const Statistics = () => {
  return (
    <Box 
    sx={{background: 
    {xs: 'radial-gradient(circle at top left, #ebe1ca, #f7efdfba 20%, rgba(255, 255, 255, 0.5) 28%)',
    md:'radial-gradient(circle at top left, #ebe1cab3, #efebe316 17%, rgba(255, 255, 255, 0.5) 28%)'}}}>
      {/* Main Container */}
      <Box
        sx={{
          background: "linear-gradient(45deg, #53A57A, #518369)",
          padding: { xs: '32px 7px', sm: '28px 10px', md: "42px 20px" },
          borderRadius: "20px",
          width: "87.5%",
          margin: "auto",
          
          position: "relative",
          zIndex: 1,
          // top: '143px'
        }}
      >
        {/* Statistics Stack */}
        <Stack
          direction="row"
          justifyContent={{xs: 'space-between',sm: 'space-between',md:"space-evenly"}}
          alignItems="center"
          flexWrap="wrap"
          width={{ xs: '100%', sm: '100%', md: "95%" }}
          margin="auto"
        >
          {/* Stat 1 */}
          <Box sx={{width: {xs: '35%', sm: '35%', md: '28%'}}}>
            <Stack direction='row' gap={{ xs: '4px', sm: '4px', md: '6px' }} sx={{justifyContent: {xs: 'center', sm: 'center'},  height: {xs: '36px', sm: 'auto'}}}>
              <Typography
                sx={{fontSize: { xs: '26px', sm: '31px', md: "54px" }}}
                color="#fff"
                fontFamily="'Kamerik205Regular3', sans-serif"
                textAlign="center"
                letterSpacing='-0.5px'
              >
                2000
              </Typography>
              <Typography sx={{fontSize: { xs: '26px', sm: '28px', md: "54px" }}}
                color="#fff"
                fontFamily="'Kamerik205Regular3', sans-serif"
                textAlign="center">
                +
              </Typography>
            </Stack>
            <Typography
              fontFamily='PoppinsLight'
              color="#fff"
              fontSize={{ xs: '10px', sm: '11px', md: "22px" }}
              // fontWeight="lighter"
              textAlign="center"
            >
              Pharmacies served <br /> and growing steadily.
            </Typography>
          </Box>

          {/* Divider 1 */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "white",
              height: {xs: '69px',md:"155px"},
              position: 'relative',
              top: '4px',
              display: { xs: "block", sm: "block" }, // Hide on small screens
            }}
          />

          {/* Stat 2 */}
          <Box sx={{width: {xs: '30%', sm: '32%', md: '28%'}}}>
            <Stack direction='row' gap={{ xs: '4px', sm: '4px', md: '6px' }} sx={{justifyContent: {xs: 'center', sm: 'center'},  height: {xs: '36px', sm: 'auto'}}}>
              <Typography
                sx={{
                  fontSize: { xs: '26px', sm: '45px', md: "54px" }
                }}
                // fontWeight="600"
                color="#fff"
                fontFamily="'Kamerik205Regular3', sans-serif"
                textAlign="center"
                letterSpacing='-0.5px'
              >
                115
              </Typography>
              <Typography sx={{fontSize: { xs: '26px', sm: '45px', md: "54px" }}}
                // fontWeight="600"
                color="#fff"
                fontFamily="'Kamerik205Regular3', sans-serif"
                textAlign="center">
                +
              </Typography>
            </Stack>
            <Typography
              fontFamily='PoppinsLight'
              color="#fff"
              fontSize={{ xs: '10px', sm: '11px', md: "22px" }}
              // fontWeight="lighter"
              textAlign="center"
            >
              Trusted brands <br /> partnered with us.
            </Typography>
          </Box>

          {/* Divider 2 */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "white",
              height: {xs: '69px',md:"155px"},
              position: 'relative',
              top: '4px',
              display: { xs: "block", sm: "block" }, // Hide on small screens
            }}
          />

          {/* Stat 3 */}
          <Box sx={{width: {xs: '30%', sm: '32%', md: '28%'}}}>
            <Stack direction='row' gap={{ xs: '4px', sm: '4px', md: '6px' }} sx={{justifyContent: {xs: 'center', sm: 'center'},  height: {xs: '36px', sm: 'auto'}}}>
              <Typography
                sx={{
                  fontSize: { xs: '26px', sm: '45px', md: "54px" }
                }}
                // fontWeight="600"
                color="#fff"
                fontFamily="'Kamerik205Regular3', sans-serif"
                textAlign="center"
                letterSpacing='-0.5px'
              >
                200k
              </Typography>
              <Typography sx={{fontSize: { xs: '26px', sm: '45px', md: "54px" }}}
                // fontWeight="600"
                color="#fff"
                fontFamily="'Kamerik205Regular3', sans-serif"
                textAlign="center">
                +
              </Typography>
            </Stack>
            <Typography
              fontFamily='PoppinsLight'
              color="#fff"
              fontSize={{ xs: '10px', sm: '11px', md: "22px" }}
              // fontWeight="lighter"
              textAlign="center"
            >
              Orders processed <br /> annually.
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Statistics;
