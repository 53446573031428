import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import anahadsidelogo from '../images/yrYJJv 3.png';
import homeIcon from '../images/home_icon.png';
import pharmacyImg from '../images/pharmacy.svg';
import charrticon from '../images/chart.png';
import docicon from '../images/document.png';
import usericon from '../images/user.png';
import anahadImg from '../images/sidebar_img.png';
import greenhomeimg from '../images/greenhomeIcon.svg'
import whitedocimg from '../images/Documentwhite.svg'
import userwhiteimg from '../images/Personwhite.svg'
import whitewallet from '../images/walletImg.svg'
import whitechart from '../images/whitechart-img.svg'
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import axios from 'axios';
import Config from '../utils/Config';
import { LogoutFunction } from './Logout/Logout';
import { ToastContainer } from 'react-toastify';

const Sidebar = () => {
  const [activeItem, setActiveItem] = useState(1);
  const location = useLocation();
  const { Logout_API, ClearLocalStorage } = LogoutFunction();

  useEffect(() => {
    const path = location.pathname;
    const active = menuItems.find(item => path.includes(item.path)) || accountItems.find(item => path.includes(item.path));
    setActiveItem(active ? active.id : 1);
  }, [location.pathname])

  // Menu items
  const menuItems = [
    { id: 1, label: 'Dashboard', image: greenhomeimg, section: 'menu', imageAct: homeIcon, path: '/dashboard' },
    { id: 2, label: 'Orders', image: charrticon, section: 'menu', imageAct: whitechart, path: '/orders' },
    { id: 3, label: 'Pharmacies', image: pharmacyImg, section: 'menu', imageAct: whitewallet, path: '/pharmacies' },
  ];

  const accountItems = [
    { id: 4, label: 'Profile', image: usericon, section: 'account', imageAct: userwhiteimg, path: '/profile' },
    // { id: 5, label: 'Sign Out', image: docicon, section: 'account',imageAct: whitedocimg,path: '/signout' },
  ];

  const handleLogout = async () => {
    await Logout_API();
    setTimeout(() => {
      ClearLocalStorage();
    }, 300);
  };

  return (
    <>
      <Box
        sx={{
          width: 240,
          // height: '100vh',
          color: '#4b4b4b',
          padding: 2,
          marginTop: '2px'
        }}>
        {/* Logo Section */}
        <Box
          sx={{
            display: 'flex',
            mt: '14px',
            mb: '24px',
            gap: '8px',
            ml: '22px'
          }}
        >
          <Box component="img" src={anahadsidelogo} alt="Logo" />
          <Typography variant="h5" fontWeight="bold" color="#2D3748">
            Anahad
          </Typography>
        </Box>

        {/* Menu Items */}
        <List>
          {menuItems.map((item) => (
            <Link to={item.path} key={item.id} style={{ textDecoration: 'none' }}>
              <ListItem
                key={item.id}
                button
                onClick={() => setActiveItem(item.id)}
                sx={{
                  display: 'flex',
                  gap: '10px',
                  borderRadius: '12px',
                  marginBottom: '8px',
                  backgroundColor: activeItem === item.id ? '#ffffff' : 'transparent',
                  color: activeItem === item.id ? '#000000' : '#6E6E6E',
                  boxShadow: activeItem === item.id ? '0px 3px 5px rgb(190 183 183 / 15%)' : 'none',
                  '&:hover': {
                    backgroundColor: activeItem === item.id ? '#ffffff' : '#F0F0F0',
                  },
                }}
              >
                <Box width="36px">
                  <Box
                    component="img"
                    src={activeItem === item.id ? item.imageAct : item.image}
                    alt={item.label}
                    sx={{
                      width: '18px',
                      height: '18px',
                      padding: '9px',
                      backgroundColor: activeItem === item.id ? '#71CA9B' : '#ffffff',
                      borderRadius: '14px',
                    }}
                  />
                </Box>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontWeight: activeItem === item.id ? 'bold' : 'normal',
                  }}
                />
              </ListItem>
            </Link>
          ))}
        </List>

        {/* Account Pages Heading */}
        <Typography
          variant="h6"
          sx={{
            textTransform: 'uppercase',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            marginY: 2,
            ml: '26px',
            color: '#2d3748',
          }}
        >
          Account Pages
        </Typography>

        {/* Account Items */}
        <List>
          {accountItems.map((item) => (
            <ListItem
              key={item.id}
              button
              onClick={() => setActiveItem(item.id)}
              sx={{
                display: 'flex',
                gap: '10px',
                borderRadius: '12px',
                marginBottom: '8px',
                backgroundColor: activeItem === item.id ? '#ffffff' : 'transparent',
                color: activeItem === item.id ? '#000000' : '#6E6E6E',
                boxShadow: activeItem === item.id ? '0px 3px 5px rgb(190 183 183 / 15%)' : 'none',
                '&:hover': {
                  backgroundColor: activeItem === item.id ? '#ffffff' : '#F0F0F0',
                },
              }}
            >
              <Box width="36px">
                <Box
                  component="img"
                  src={activeItem === item.id ? item.imageAct : item.image}
                  alt={item.label}
                  sx={{
                    width: '18px',
                    height: '18px',
                    padding: '9px',
                    backgroundColor: activeItem === item.id ? '#71CA9B' : '#ffffff',
                    borderRadius: '14px',
                  }}
                />
              </Box>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontWeight: activeItem === item.id ? 'bold' : 'normal',
                }}
              />
            </ListItem>
          ))}
          <ListItem
            onClick={handleLogout}
            sx={{
              display: 'flex',
              gap: '10px',
              borderRadius: '12px',
              marginBottom: '8px',
              backgroundColor: 'transparent',
              color: '#6E6E6E',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#F0F0F0',
              },
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              cursor: 'pointer'
            }}
          >
            <Box width="36px">
              <Box
                component="img"
                src={docicon}
                alt={'documentIcon'}
                sx={{
                  width: '18px',
                  height: '18px',
                  padding: '9px',
                  backgroundColor: '#ffffff',
                  borderRadius: '14px',
                }}
              />
            </Box>
            Sign Out</ListItem>
        </List>

        {/* Bottom Image */}
        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          <Box component="img" src={anahadImg} alt="Anahad" />
        </Box>
      </Box>
      <ToastContainer/>
    </>
  );
};

export default Sidebar;
