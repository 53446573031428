import { Box, Divider, List, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import logo from "../../images/logo_svg.svg"
import { Link } from "react-router-dom";
import icon1 from "../../images/website-images/facebook.png"
import icon2 from "../../images/website-images/linkedin_icon.png"
import icon3 from "../../images/website-images/twitter.png"

const Footer = () => {

    const navList = [
        { id: 1, name: "Home", path: "/" },
        { id: 2, name: "Products", path: "/products" },
        { id: 3, name: "About", path: "/aboutus" },
        { id: 4, name: "Contact", path: "/contact" },
    ];

    return (
        <>
            <Box sx={{ bgcolor: '#60BC8C', padding: { xs: '2rem 1.6rem', sm: '2rem 1rem', md: '2rem 0rem' } }}>
                <Box width={{md:'80%'}} margin={{md:'auto'}}>
                    <Stack direction={{ xs: 'column', sm: 'row', md: 'row' }} gap={{xs: '10px'}} justifyContent='space-between' mt='10px' mb={{xs: '15px', sm: '30px',md:'30px'}}>
                        <Stack direction='row' alignItems='center' gap='12px'>
                            <Box component={'img'} src={logo} alt="Anahad Logo" sx={{ width: "40px", height: "40px" }} />
                            <Typography variant="h6" fontWeight="bold" color="#353A32" textTransform='uppercase' fontFamily="'MuktaExtraBold', sans-serif'">
                                Anahad
                            </Typography>
                        </Stack>

                        <List sx={{ display: "flex", gap: { xs: '7px', sm: '10px', md: '24px' }, justifyContent: {xs: 'space-between'} }}>
                            {
                                navList.map((item) => {
                                    return (
                                        <>
                                            <ListItem key={item?.id} sx={{
                                                width: "auto",
                                                padding: { xs: '8px 8px', sm: '8px', md: '8px 16px' },
                                                "& a": {
                                                    fontFamily: "'GeneralSansMedium', sans-serif",
                                                    textDecoration: "none",
                                                    textTransform: 'capitalize',
                                                    color: "#3c4959",
                                                    fontWeight: 'regular',
                                                    fontSize: "15px",
                                                    // "&:hover": { color: "#3182CE", },
                                                    lineHeight: '18px',
                                                    borderBottom: 'none',

                                                },
                                            }}>
                                                <Link to={item?.path}>{item?.name}</Link>
                                            </ListItem>
                                        </>
                                    )
                                })
                            }
                        </List>

                    </Stack>
                    <Divider orientation="horizontal" style={{ borderWidth: '1.5px', borderColor: '#3A3F3C' }} />
                    <Stack
                        direction={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
                        gap={{ xs: '24px', sm: '24px' }}
                        alignItems={{ xs: 'center', sm: 'center' }}
                        justifyContent='space-between' mt='30px'>
                        <Stack direction='row' display={{ xs: 'none', sm: 'none', md: 'flex' }}>
                            <Typography fontSize='14px' color="#3A3F3C" sx={{ fontFamily: "'GeneralSansRegular', sans-serif" }}>All rights reserved <span style={{ fontSize: '9px', padding: '1px 4px', border: '1px solid black', borderRadius: '15px' }}>R</span> anahad.in</Typography>
                            <Typography fontSize='14px' color="#3A3F3C" ml='6px' sx={{ fontFamily: "'GeneralSansRegular', sans-serif" }}>| Terms and Conditions apply!</Typography>
                        </Stack>
                        <Typography fontSize='14px' color="#3A3F3C" sx={{ fontFamily: "'GeneralSansRegular', sans-serif", display: { xs: 'block', md: 'none' }, width: '80%', textAlign: 'center' }}>All rights reserved
                            <span style={{ fontSize: '9px', padding: '1px 4px', border: '1px solid #3A3F3C', borderRadius: '15px', margin: '0px 4px' }}>R</span>
                            anahad.in | Terms and Conditions apply!</Typography>

                        <Stack direction='row' gap='20px'>
                            <Box component={'img'} src={icon1} alt="facebook" width='30px' height='30px' />
                            <Box component={'img'} src={icon2} alt="linkdin" width='30px' height='30px' />
                            <Box component={'img'} src={icon3} alt="twitter" width='30px' height='30px' />
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default Footer